import React from 'react'

// App components
import BracesExamples from '../../components/BracesExamples'
import Layout from '../../components/layout'
import PageTitle from '../../components/PageTitle'

// App content
import clear from '../../assets/ClearBracesPicture.png'
import traditional from '../../assets/Subpage_Braces-03.png'

// App utils
import { themeStyles, colors, metrics, presets } from '../../utils/theme'

const styles = {
  imageAndTextHolder: {
    display: 'flex',
    alignItems: 'center',
    padding: '20px 0',
    flexDirection: 'column',
    [presets.Tablet]: {
      flexDirection: 'row',
    },
  },
  image: {
    maxWidth: '300px',
    maxHeight: '300px',
    marginLeft: 0,
    [presets.Tablet]: {
      marginLeft: metrics.defaultMargin,
    },
  },
  text: {
    textAlign: 'center',
    [presets.Tablet]: {
      textAlign: 'left',
    },
  },
}

class Braces extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <div style={themeStyles.contentWidth}>
          <PageTitle title="Braces" bannerKey={'ORTHODONTICS'} />
          <BracesExamples selectedIndexs={[0, 1]} />
          <div
            css={[
              styles.imageAndTextHolder,
              { backgroundColor: colors.lightGrey },
            ]}
          >
            <img src={traditional} css={styles.image} />
            <div css={themeStyles.textPadding}>
              <h2 css={styles.text}>Traditional Braces</h2>
              <p css={styles.text}>
                Our traditional metal braces at Collins Orthodontics are
                different than the braces patients wore 10 years ago. Technology
                used at Collins allows us to offer smaller and lower profile
                prescription brackets that lead to easier cleaning access and
                overall faster treatment. And whether you need a pop of color
                for a special occasion, or you are looking for subtle and
                professional chic for a big upcoming meeting, our traditional
                braces will include color options for everyone.
              </p>
            </div>
          </div>
          <div css={styles.imageAndTextHolder}>
            <img src={clear} css={styles.image} />
            <div css={themeStyles.textPadding}>
              <h2 css={styles.text}>Clear Braces</h2>
              <p css={styles.text}>
                So you would like braces, but having metal and colored ties on
                your teeth does not sound appealing at all. The good news is
                that you have another option –clear braces. Clear braces serve
                the exact same purpose as metal braces. They are glued to the
                teeth and they hold the wire in place in order to apply forces
                from the wire to gently move your teeth. The main difference
                with clear braces is they are more esthetic and less noticeable
                when compared to traditional metal braces. In fact, most clear
                braces will not be noticeable at all in photos, so if you are in
                a wedding, want family pictures, or you have an upcoming event,
                you can rest easy that the photos of you smiling with braces
                will look great. Clear braces are a wonderful (and popular)
                option for adults and teens that are much less noticeable than
                traditional metal braces.
              </p>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Braces
